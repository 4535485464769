import { mat4 } from "gl-matrix";
import { Vector3D } from "../../models/vector3d";
export class ViewBox {
    constructor(_library) {
        this._library = _library;
        this.wMat = mat4.create();
        this.vMat = mat4.create();
        this.pMat = mat4.create();
        this.eye = null;
        this.calculate();
    }
    map(val, inMin, inMax, outMin, outMax) {
        return (val - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
    }
    calculate() {
        const { width, height, webgl } = this._library.configuration;
        const { enabled, pitch, yaw, zoom, ortho, fov } = webgl.camera;
        if (enabled) {
            //#region Camera matrix (view matrix)
            const cameraMatrix = mat4.rotateY(mat4.create(), mat4.create(), pitch);
            mat4.rotateX(cameraMatrix, cameraMatrix, yaw);
            mat4.translate(cameraMatrix, cameraMatrix, [0, 0, zoom.value]);
            const target = [0, 0, 0];
            const eye = [cameraMatrix[12], cameraMatrix[13], cameraMatrix[14]];
            const up = [0, 1, 0];
            mat4.lookAt(this.vMat, eye, target, up);
            this.eye = Vector3D.fromArray(eye);
            this.eye.x = this.map(this.eye.x, zoom.value * -1, zoom.value, -1, +1);
            this.eye.y = this.map(this.eye.y, zoom.value * -1, zoom.value, -1, +1);
            this.eye.z = this.map(this.eye.z, zoom.value * -1, zoom.value, -1, +1);
            // mat4.invert(this.vMat, cameraMatrix);
            //#endregion
            if (ortho)
                mat4.ortho(this.pMat, -1, 1, -1, 1, .000001, 15);
            else
                mat4.perspective(this.pMat, fov, width / height, .00001, 15);
        }
    }
    recalculate() {
        this.calculate();
    }
    getResolutionVector() {
        const { width, height, depth } = this._library.configuration;
        return [width, height, depth];
    }
}
