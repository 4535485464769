import { Vector3D } from "./vector3d";
import { isVector4D } from "./vector";
export const ZeroVector4D = { x: 0, y: 0, z: 0, w: 0 };
export class Vector4D extends Vector3D {
    constructor(vec = { ...ZeroVector4D }) {
        super(vec);
        this.w = vec.w;
    }
    get components() {
        return super.components.concat([this.w]);
    }
    clone() {
        const { x, y, z, w } = this;
        return new Vector4D({ x, y, z, w });
    }
    add(vectorOrScalar) {
        if (typeof vectorOrScalar === 'number') {
            super.add(vectorOrScalar);
            this.w += vectorOrScalar;
        }
        else {
            super.add(vectorOrScalar);
            if (isVector4D(vectorOrScalar))
                this.w += vectorOrScalar.w;
        }
        return this;
    }
    sub(vector) {
        super.sub(vector);
        if (isVector4D(vector))
            this.w -= vector.w;
        return this;
    }
    div(scalar) {
        super.div(scalar);
        this.w /= scalar;
        checkVector(this);
        return this;
    }
    mul(scalar) {
        super.mul(scalar);
        this.w *= scalar;
        checkVector(this);
        return this;
    }
    get length() {
        return this.norm.euclidean;
    }
    unit() {
        return this.div(this.length);
    }
    cross(vector) {
        // TODO: Cross for 4D
        // const { x, y, z } = this.clone();
        // this.x = y * vector.z - z * vector.y;
        // this.y = z * vector.x - x * vector.z;
        // this.z = x * vector.y - y * vector.x;
        // return this;
        return super.cross(vector);
    }
    static fromArray(components) {
        const [x, y, z, w] = components;
        return new Vector4D({ x, y, z, w });
    }
}
function checkVector(vector) {
    if (isNaN(vector.x) || isNaN(vector.y) || isNaN(vector.z) || isNaN(vector.w))
        debugger;
}
