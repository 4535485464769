import { Vector3D } from "../../models/vector3d";
export var TransitionEasingFunction;
(function (TransitionEasingFunction) {
    TransitionEasingFunction[TransitionEasingFunction["LINEAR"] = 1] = "LINEAR";
    TransitionEasingFunction[TransitionEasingFunction["QUADRATIC_IN"] = 2] = "QUADRATIC_IN";
    TransitionEasingFunction[TransitionEasingFunction["QUADRATIC_OUT"] = 3] = "QUADRATIC_OUT";
    TransitionEasingFunction[TransitionEasingFunction["QUADRATIC_IN_OUT"] = 4] = "QUADRATIC_IN_OUT";
})(TransitionEasingFunction || (TransitionEasingFunction = {}));
export class TransitionSpecificationBuilder {
    constructor(_particle) {
        this._particle = _particle;
        this.specification = {
            enabled: false,
            start: new Vector3D(),
            end: new Vector3D(),
            startTime: 0,
            endTime: 0,
            easing: TransitionEasingFunction.LINEAR,
            committed: false,
        };
        this._timeout = null;
        this._transitionCallback = null;
        this.tryInvokeCallback = () => {
            if (this._transitionCallback)
                this._transitionCallback();
            this._transitionCallback = null;
        };
    }
    enable(startTime) {
        this.specification.startTime = startTime;
        this.specification.enabled = true;
        return this;
    }
    // within how many ms
    within(value) {
        this.specification.startTime = this.specification.startTime + value;
        return this;
    }
    from(value) {
        this.specification.start = value;
        return this;
    }
    to(value) {
        this.specification.end = value;
        // TODO: Dunno what to do here.
        // TODO: Cannot update coords when in transition, due to performances
        // TODO: Cannot move to the end due to possible interactions (e.g. alpha when near borders)
        // this._particle.coords = new Vector3D(value);
        return this;
    }
    in(value) {
        this.specification.endTime = this.specification.startTime + value;
        // clearTimeout(this._timeout);
        // const modeSwitchDuration = 600; // TODO: Retrieve from real insights
        // this._timeout = setTimeout(this.tryInvokeCallback, value - modeSwitchDuration)
        return this;
    }
    easing(value) {
        this.specification.easing = value;
        return this;
    }
    then(callback) {
        this._transitionCallback = callback;
        return this;
    }
    commit() {
        this.specification.committed = true;
        this._particle.notifyUpdated();
        return this;
    }
}
