export var Unit;
(function (Unit) {
    Unit["VMIN"] = "vmin";
    Unit["VMAX"] = "vmax";
    Unit["VW"] = "vw";
    Unit["VH"] = "vh";
    Unit["VD"] = "vd";
    Unit["PX"] = "px";
    Unit["ABS"] = "abs"; // [-1, 1]
})(Unit || (Unit = {}));
export const getPxFromUnit = (length, unit, width, height, depth, pixelRatio) => {
    if (unit === Unit.PX)
        return length * pixelRatio;
    if (unit === Unit.VW)
        return (length / 100) * width;
    if (unit === Unit.VH)
        return (length / 100) * height;
    if (unit === Unit.VD)
        return (length / 100) * depth;
    const vmax = Math.max(width, height, depth);
    if (unit === Unit.VMAX)
        return (length / 100) * vmax;
    const vmin = Math.min(width, height, depth);
    if (unit === Unit.ABS || unit === Unit.VMIN)
        return (length / 100) * vmin;
};
