import { PluginAdapter, HookType } from "./plugin/plugin-adapter";
import { DrawingInterface } from "./drawing/drawing-interface";
import { Renderer2D } from "./rendering/renderer-2d";
import { getDefault, LazyFactory } from "./utils/object-utils";
import { SystemBridgeEventNotification } from "./drawing/system-bridge";
import { DefaultParticleSystem } from "./systems/default-particle-system";
import { NaiveProximityDetectionSystemBuilder } from "./models/proximity-detection/naive-proximity-detection-system";
import { performanceMetricsHelper } from "./utils/performance-metrics";
export const getDefaultParams = () => ({
    selectorOrCanvas: '#canvas',
    renderer: new LazyFactory(() => Renderer2D),
    systems: [DefaultParticleSystem],
    proximityDetectionSystem: NaiveProximityDetectionSystemBuilder.build(),
    backgroundColor: [0, 0, 0, 0],
    detectRetina: true,
    fpsLimit: 0,
    features: [],
    camera: {
        enabled: true,
        pitch: 0,
        yaw: 0,
        zoom: {
            value: 2,
            locked: false
        },
        ortho: false,
        fov: Math.PI / 5,
        depthOfField: false
    },
    events: {
        resize: {
            enabled: true,
            debounce: -1
        }
    },
});
export class Main extends DrawingInterface {
    constructor(params) {
        super();
        this.params = params;
        this._plugin = new PluginAdapter();
        this.configuration = {
            initialized: false,
        };
        this.systems = [];
        this.proximityDetectionSystem = null;
        this.renderer = null;
        this._resizeDebounceTimer = null;
        this.time = 0;
        this.deltaTime = 0;
        this._lastPerf = 0;
        this._loop = this._loop.bind(this);
    }
    ;
    start() {
        this._initParams();
        this._initRenderer();
        this._initContext();
        this._initCanvas();
        this._initResizeEventListeners();
        this._initSystems();
        this._initProximityDetectionSystems();
        this._preStart();
        this._loop();
    }
    _initParams() {
        this.params = getDefault(this.params, getDefaultParams());
        this.systems = this.params.systems.map(builder => new builder(this));
        this.proximityDetectionSystem = new this.params.proximityDetectionSystem(this);
        this.renderer = new this.params.renderer(this._plugin);
        let canvas = this.params.selectorOrCanvas;
        if (typeof canvas === 'string')
            canvas = document.querySelector(canvas);
        this._canvas = canvas;
    }
    _initRenderer() {
        this.renderer.register();
        this._plugin.exec(HookType.RENDERER_INIT, this);
    }
    _initContext() {
        this._plugin.exec(HookType.CONTEXT_INIT, this);
    }
    _initCanvas() {
        this._configureSize();
        this._plugin.exec(HookType.CANVAS_INIT, this);
    }
    _initResizeEventListeners() {
        const resizeEvent = this.params.events.resize;
        if (resizeEvent.enabled) {
            window.addEventListener('resize', () => {
                if (resizeEvent.debounce === -1 || resizeEvent.debounce === 0) {
                    this._configureSize();
                    this._plugin.exec(HookType.WINDOW_RESIZE, this);
                }
                else {
                    clearTimeout(this._resizeDebounceTimer);
                    this._resizeDebounceTimer = setTimeout(() => {
                        this._configureSize();
                        this._plugin.exec(HookType.WINDOW_RESIZE, this);
                    }, resizeEvent.debounce);
                }
            });
        }
    }
    _configureSize() {
        let { clientWidth: width, clientHeight: height } = this.canvas;
        if (this.params.detectRetina) {
            const pixelRatio = window.devicePixelRatio;
            if (pixelRatio > 1) {
                this.configuration.isRetina = true;
                this.configuration.pixelRatio = pixelRatio;
                width = this.canvas.offsetWidth * pixelRatio;
                height = this.canvas.offsetHeight * pixelRatio;
            }
            else {
                this.configuration.pixelRatio = 1;
                this.configuration.isRetina = false;
            }
        }
        this.configuration.width = width;
        this.configuration.height = height;
        this.configuration.depth = Math.max(width, height);
        this.canvas.width = width;
        this.canvas.height = height;
    }
    _initSystems() {
        this.systems.forEach(x => x.attach());
    }
    _initProximityDetectionSystems() {
        this.proximityDetectionSystem.init();
    }
    _preStart() {
        this._plugin.exec(HookType.PRE_START, this);
    }
    _loop() {
        const fpsLimit = this.params.fpsLimit;
        // #region Update
        const currentPerf = performance.now();
        let delta = currentPerf - this._lastPerf;
        const fps = 1000 / delta;
        if (fpsLimit > 0 && delta < (1000 / fpsLimit) + 1) {
            requestAnimationFrame(this._loop);
        }
        else {
            delta = Math.min(delta, 30);
            this.deltaTime = delta;
            this.time += this.deltaTime;
            this._lastPerf = currentPerf;
            performanceMetricsHelper.set('fps', fps);
            this.systems.forEach(system => {
                system.updateInternalParameters(this.deltaTime, this.time);
                if (system.tick)
                    system.tick(this.deltaTime, this.time);
            });
            this._plugin.exec(HookType.UPDATE, this);
            // #endregion
            // #region Draw
            this._plugin.exec(HookType.CANVAS_CLEAR, this);
            this._plugin.exec(HookType.DRAW, this);
            // #endregion
            // Loop
            requestAnimationFrame(this._loop);
        }
    }
    notify(type, system) {
        if (type === SystemBridgeEventNotification.SYSTEM_UPDATED) {
            this._plugin.exec(HookType.SYSTEM_UPDATED, this);
        }
    }
    getAllParticles() {
        return this.systems.map(system => system.getParticles()).flat();
    }
    getAllLinkableParticles() {
        let linesConfiguration = { required: false };
        let particles = [];
        this.systems
            .forEach(system => {
            if (system.links.required) {
                linesConfiguration = system.links;
                particles.push(system.getParticles());
            }
        });
        return [particles.flat(), linesConfiguration];
    }
    feedProximityDetectionSystem(objects) {
        this.proximityDetectionSystem.update(objects);
    }
    getNeighbours(particle, radius) {
        return this.proximityDetectionSystem.getNeighbours(particle, radius);
    }
    getProximityDetectionSystem() {
        return this.proximityDetectionSystem;
    }
}
export var Feature;
(function (Feature) {
    Feature["LINKS"] = "links";
    Feature["DIRECTIONS"] = "directions";
    Feature["QUAD_TREE"] = "quadTree";
})(Feature || (Feature = {}));
export const init = (params) => {
    new Main(params).start();
};
