import { VectorNorm } from "./vector-norm";
export const ZeroVector3D = { x: 0, y: 0, z: 0 };
export class Vector3D {
    constructor({ x, y, z } = { ...ZeroVector3D }) {
        this.x = x;
        this.y = y;
        this.z = z;
        this.norm = new VectorNorm(this);
    }
    get components() {
        return [this.x, this.y, this.z];
    }
    clone() {
        const { x, y, z } = this;
        return new Vector3D({ x, y, z });
    }
    add(vectorOrScalar) {
        if (typeof vectorOrScalar === 'number') {
            this.x += vectorOrScalar;
            this.y += vectorOrScalar;
            this.z += vectorOrScalar;
        }
        else {
            this.x += vectorOrScalar.x;
            this.y += vectorOrScalar.y;
            this.z += vectorOrScalar.z;
        }
        return this;
    }
    sub(vector) {
        this.x -= vector.x;
        this.y -= vector.y;
        this.z -= vector.z;
        return this;
    }
    div(scalar) {
        this.x /= scalar;
        this.y /= scalar;
        this.z /= scalar;
        checkVector(this);
        return this;
    }
    mul(scalar) {
        this.x *= scalar;
        this.y *= scalar;
        this.z *= scalar;
        checkVector(this);
        return this;
    }
    dot(vector) {
        return this.x * vector.x + this.y * vector.y + this.z * vector.z;
    }
    // TODO: Verify
    angle(vector) {
        return Math.acos(this.dot(vector) / (this.length * vector.length));
    }
    get length() {
        return this.norm.euclidean;
    }
    unit() {
        if (this.length > .0001)
            return this.div(this.length);
        return { ...ZeroVector3D };
    }
    cross(vector) {
        const { x, y, z } = this.clone();
        this.x = y * vector.z - z * vector.y;
        this.y = z * vector.x - x * vector.z;
        this.z = x * vector.y - y * vector.x;
        return this;
    }
    static fromArray(components) {
        const [x, y, z] = components;
        return new Vector3D({ x, y, z });
    }
}
function checkVector(vector) {
    if (isNaN(vector.x) || isNaN(vector.y) || isNaN(vector.z))
        debugger;
}
