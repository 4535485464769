import { Vector3D } from "./vector3d";
import { Vector4D } from "./vector4d";
import { ParticleEventType, BaseListenableParticle } from "./base-particle";
import { getDefault } from "../utils/object-utils";
import { TransitionSpecificationBuilder } from "../systems/transition/transition-specification";
import { randomValueFromBoundary, valueFromRandomOptions } from "../utils/random";
import { getColor } from "../rendering/renderer-webgl";
//#region Movement
export var ParticleDirection;
(function (ParticleDirection) {
    ParticleDirection["UP"] = "up";
    ParticleDirection["RIGHT"] = "right";
    ParticleDirection["DOWN"] = "down";
    ParticleDirection["LEFT"] = "left";
    ParticleDirection["FRONT"] = "front";
    ParticleDirection["BACK"] = "back";
})(ParticleDirection || (ParticleDirection = {}));
const defaultVelocityConfigurationOptions = {
    randomize: false,
    boundary: {
        min: 0,
        max: 1
    }
};
//#endregion
export class Particle extends BaseListenableParticle {
    constructor(coords = new Vector3D(), _manager) {
        super();
        this.coords = coords;
        this._manager = _manager;
        this._transitionSpecificationBuilder = new TransitionSpecificationBuilder(this);
        this.size = 2;
        this.velocity = new Vector3D();
        this.color = new Vector4D({
            x: 255,
            y: 255,
            z: 255,
            w: 1
        });
        this.alpha = 1;
        this._lastTickDelta = -1;
        this._lastTickTime = -1;
    }
    getTransitionSpecification() {
        return this._transitionSpecificationBuilder && this._transitionSpecificationBuilder.specification;
    }
    useTransition() {
        const transitionSpecificationBuilder = new TransitionSpecificationBuilder(this);
        this._transitionSpecificationBuilder = transitionSpecificationBuilder;
        return transitionSpecificationBuilder.enable(this._lastTickTime);
    }
    setVelocity(direction, options) {
        let velocityOptions;
        if (options === null) {
            velocityOptions = defaultVelocityConfigurationOptions;
        }
        else {
            velocityOptions = getDefault(options, defaultVelocityConfigurationOptions);
        }
        const { min, max } = options.boundary;
        const range = max - min;
        const velocity = this.velocity;
        switch (direction) {
            case ParticleDirection.UP:
                velocity.y = 1;
                velocityOptions.randomize && this._randomizeVectorComponent(velocity, 'y', range, min);
                break;
            case ParticleDirection.RIGHT:
                velocity.x = 1;
                velocityOptions.randomize && this._randomizeVectorComponent(velocity, 'x', range, min);
                break;
            case ParticleDirection.DOWN:
                velocity.y = -1;
                velocityOptions.randomize && this._randomizeVectorComponent(velocity, 'y', range, min);
                break;
            case ParticleDirection.LEFT:
                velocity.x = -1;
                velocityOptions.randomize && this._randomizeVectorComponent(velocity, 'x', range, min);
                break;
            case ParticleDirection.FRONT:
                velocity.z = 1;
                velocityOptions.randomize && this._randomizeVectorComponent(velocity, 'z', range, min);
                break;
            case ParticleDirection.BACK:
                velocity.z = -1;
                velocityOptions.randomize && this._randomizeVectorComponent(velocity, 'z', range, min);
                break;
        }
        this.velocity = velocity;
    }
    _randomizeVectorComponent(vector, component, range, min) {
        if (vector[component])
            vector[component] = vector[component] * Math.random() * range + min;
    }
    setColor(r, g, b, a) {
        const [x, y, z, w] = getColor(r, g, b, a);
        this.color = new Vector4D({ x, y, z, w });
        this.call(ParticleEventType.COLOR_UPDATE, this);
    }
    setSize(valueOrRandom) {
        if (typeof valueOrRandom === 'number') {
            this.size = valueOrRandom;
        }
        else {
            if (this._randomOptionIsBoundary(valueOrRandom)) {
                this.size = randomValueFromBoundary(valueOrRandom);
            }
            else {
                this.size = valueFromRandomOptions(valueOrRandom);
            }
        }
        this.size = this.size * this._manager.configuration.pixelRatio;
    }
    _randomOptionIsBoundary(randomOption) {
        const option = randomOption;
        return option.min !== undefined && option.max !== undefined &&
            option.boundary === undefined && option.value === undefined;
    }
    setAlpha(value) {
        this.color.w = value;
        this.call(ParticleEventType.COLOR_UPDATE, this);
    }
    update(delta, time) {
        this._lastTickDelta = delta;
        this._lastTickTime = time;
        const transition = this._transitionSpecificationBuilder;
        if (transition.specification.enabled) {
            if (time) {
                if (time + 16 >= transition.specification.endTime)
                    transition.tryInvokeCallback();
            }
        }
        else {
            this.updatePosition();
            this.call(ParticleEventType.POSITION_UPDATE, this);
            this.notifyUpdated();
        }
    }
    // Updates position according to velocity
    updatePosition() {
        if (this.velocity) {
            this.coords.add(this.velocity);
        }
    }
    notifyUpdated() {
        this.call(ParticleEventType.UPDATE, this);
    }
}
