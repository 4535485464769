export class PerformanceMetricsHelper {
    constructor() {
        this._metrics = {};
        if (typeof window !== undefined)
            window['metrics'] = this;
    }
    set(key, value) {
        this._metrics[key] = value;
    }
    get() {
        return this._metrics;
    }
}
export const performanceMetricsHelper = new PerformanceMetricsHelper();
