export class SystemTimer {
    constructor() {
        this._timers = [];
    }
    add(callback, since, when, recurrent) {
        this._timers.push({ callback, since, when, recurrent, fired: false });
    }
    check(currentTime) {
        this._timers.forEach(timer => {
            if (currentTime >= timer.when) {
                timer.callback();
                if (!timer.recurrent) {
                    timer.fired = true;
                }
                else {
                    timer.when = currentTime + (timer.when - timer.since);
                    timer.since = currentTime;
                }
            }
        });
        this._timers = this._timers.filter(h => !h.fired);
    }
    remove(timerHandle) {
        this._timers = this._timers.filter(h => h !== timerHandle);
    }
}
