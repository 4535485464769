import { HookType } from "../plugin/plugin-adapter";
export class Renderer2D {
    constructor(_pluginAdapter) {
        this._pluginAdapter = _pluginAdapter;
    }
    register() {
        this._pluginAdapter.addAfter(HookType.CONTEXT_INIT, this._initContext);
        this._pluginAdapter.addAfter(HookType.DRAW, this._draw);
    }
    _initContext(drawingInterface) {
        drawingInterface.context = drawingInterface.canvas.getContext('2d');
    }
    _draw(systems) {
        systems.forEach(x => {
            const particles = x.getParticles();
        });
    }
}
