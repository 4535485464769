export const getDefault = (gotObject, defaultObject) => {
    if (gotObject === undefined) {
        if (defaultObject instanceof LazyFactory) {
            return defaultObject.build();
        }
        else {
            return defaultObject;
        }
    }
    if (typeof defaultObject === 'object' && !Array.isArray(defaultObject) && !(defaultObject instanceof LazyFactory)) {
        const newGotObject = {};
        for (const key in defaultObject) {
            newGotObject[key] = getDefault(gotObject[key], defaultObject[key]);
        }
        return newGotObject;
    }
    return gotObject;
};
export class LazyFactory {
    constructor(_callback) {
        this._callback = _callback;
    }
    build() {
        return this._callback();
    }
}
export const stringFormat = function (str, ..._) {
    if (arguments.length) {
        var t = typeof arguments[1];
        var key;
        var args = ("string" === t || "number" === t) ?
            Array.prototype.slice.call(arguments)
            : arguments[1];
        for (key in args) {
            str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
        }
    }
    return str;
};
