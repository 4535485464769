export class Listenable {
    constructor() {
        this._attachedEventListeners = [];
    }
    on(type, callback) {
        this._attachedEventListeners.push({ type, callback });
    }
    off(type, callback) {
        let eventIndex = -1;
        if (callback !== undefined) {
            eventIndex = this._attachedEventListeners.findIndex(e => e.type === type && e.callback === callback);
        }
        else {
            eventIndex = this._attachedEventListeners.findIndex(e => e.type === type);
        }
        if (eventIndex > -1) {
            this._attachedEventListeners =
                this._attachedEventListeners.slice(0, eventIndex).concat(this._attachedEventListeners.slice(eventIndex + 1));
        }
    }
}
export class ListenableDispatcher extends Listenable {
    call(type, model) {
        this._attachedEventListeners
            .filter(e => e.type === type)
            .forEach(e => e.callback(model));
    }
}
