export const valueFromRandomOptions = (options) => {
    if (options.randomize)
        return randomValueFromBoundary(options.boundary);
    return options.value;
};
export const randomValueFromBoundary = (boundary) => {
    const { min, max } = boundary;
    const range = max - min;
    return Math.random() * range + min;
};
