import { getDefault } from "../../utils/object-utils";
export class NaiveProximityDetectionSystem {
    constructor(manager) {
        this.manager = manager;
        this._objects = [];
    }
    init() { }
    update(objects) {
        this._objects = objects;
    }
    getNeighbours(a, radius = NaiveProximityDetectionSystem.params.radius) {
        return this._objects
            .filter(b => a !== b && this.getDistance(a, b) < radius);
    }
    getDistance(obj1, obj2) {
        return Math.hypot(obj2.coords.x - obj1.coords.x, obj2.coords.y - obj1.coords.y, obj2.coords.z - obj1.coords.z);
    }
}
export class NaiveProximityDetectionSystemBuilder {
    static build(optionalParams) {
        const params = getDefault(optionalParams, {
            radius: 300
        });
        NaiveProximityDetectionSystem.params = params;
        return NaiveProximityDetectionSystem;
    }
}
