export var BaseUniforms;
(function (BaseUniforms) {
    BaseUniforms["RESOLUTION"] = "v_res";
    BaseUniforms["WORLD"] = "m_world";
    BaseUniforms["VIEW"] = "m_view";
    BaseUniforms["PROJECTION"] = "m_projection";
    BaseUniforms["T"] = "f_t";
    BaseUniforms["ZOOM"] = "f_zoom";
})(BaseUniforms || (BaseUniforms = {}));
export var BaseUniformAggregationType;
(function (BaseUniformAggregationType) {
    BaseUniformAggregationType["CAMERA"] = "camera";
    BaseUniformAggregationType["RESOLUTION"] = "resolution";
})(BaseUniformAggregationType || (BaseUniformAggregationType = {}));
export class BaseProgram {
    constructor(_gl, _vertexShaderText, _fragmentShaderText, _attributes, _uniforms = [], _viewBox, _libraryInterface) {
        this._gl = _gl;
        this._vertexShaderText = _vertexShaderText;
        this._fragmentShaderText = _fragmentShaderText;
        this._attributes = _attributes;
        this._uniforms = _uniforms;
        this._viewBox = _viewBox;
        this._libraryInterface = _libraryInterface;
        this._uniformsToUpdate = {
            camera: true,
            resolution: true,
        };
        this.getAttributeLocation = this.attr;
        this.getUniformLocation = this.uni;
        this.checkShaderCompilation = (shader, context) => {
            if (!context.getShaderParameter(shader, context.COMPILE_STATUS)) {
                throw new Error(`Error compiling shader: ${context.getShaderInfoLog(shader)}`);
            }
        };
        this.checkProgramLink = (program, context) => {
            if (!context.getProgramParameter(program, context.LINK_STATUS)) {
                throw new Error(`Error linking program: ${context.getProgramInfoLog(program)}`);
            }
        };
        this.checkProgramValidation = (program, context) => {
            if (!context.getProgramParameter(program, context.VALIDATE_STATUS)) {
                throw new Error(`Error linking program: ${context.getProgramInfoLog(program)}`);
            }
        };
        this.compileShaders();
        this.createProgram();
        this.findLocations();
    }
    uniformChanged(uniform) {
        this._uniformsToUpdate[uniform] = true;
    }
    update(deltaT, T) { }
    draw(deltaT, T) {
        this._gl.useProgram(this.program);
        this._gl.uniform1f(this.uni(BaseUniforms.T), T);
        if (this._uniformsToUpdate[BaseUniformAggregationType.RESOLUTION]) {
            this._gl.uniform3fv(this.uni(BaseUniforms.RESOLUTION), new Float32Array(this.getResolutionVector()));
            this._uniformsToUpdate[BaseUniformAggregationType.RESOLUTION] = false;
        }
        this._gl.uniformMatrix4fv(this.uni(BaseUniforms.WORLD), false, this._viewBox.wMat);
        this._gl.uniformMatrix4fv(this.uni(BaseUniforms.VIEW), false, this._viewBox.vMat);
        this._gl.uniformMatrix4fv(this.uni(BaseUniforms.PROJECTION), false, this._viewBox.pMat);
    }
    getResolutionVector() {
        return this._viewBox.getResolutionVector();
    }
    //#region Program container
    get program() {
        return this._program;
    }
    compileShaders() {
        this._vertexShader = this._gl.createShader(this._gl.VERTEX_SHADER);
        this._gl.shaderSource(this._vertexShader, this._vertexShaderText);
        this._gl.compileShader(this._vertexShader);
        this.checkShaderCompilation(this._vertexShader, this._gl);
        this._fragmentShader = this._gl.createShader(this._gl.FRAGMENT_SHADER);
        this._gl.shaderSource(this._fragmentShader, this._fragmentShaderText);
        this._gl.compileShader(this._fragmentShader);
        this.checkShaderCompilation(this._fragmentShader, this._gl);
    }
    createProgram() {
        this._program = this._gl.createProgram();
        this._gl.attachShader(this._program, this._vertexShader);
        this._gl.attachShader(this._program, this._fragmentShader);
        this._gl.linkProgram(this._program);
        this.checkProgramLink(this._program, this._gl);
        this._gl.validateProgram(this._program);
        this.checkProgramValidation(this._program, this._gl);
    }
    findLocations() {
        this._uniforms = this._uniforms.concat(Object.values(BaseUniforms));
        this._attributesLocations =
            this._attributes
                .reduce((acc, att) => {
                const attributeLocation = this._gl.getAttribLocation(this._program, att);
                if (attributeLocation === -1)
                    console.warn(`Cannot find attribute ${att}.`);
                acc[att] = attributeLocation;
                return acc;
            }, {});
        this._uniformsLocations =
            this._uniforms
                .reduce((acc, uni) => {
                const uniformLocation = this._gl.getUniformLocation(this._program, uni);
                if (uniformLocation === -1)
                    console.warn(`Cannot find uniform ${uni}`);
                acc[uni] = uniformLocation;
                return acc;
            }, {});
    }
    attr(attrib) {
        return this._attributesLocations[attrib];
    }
    uni(uniform) {
        return this._uniformsLocations[uniform];
    }
}
