import { SystemBridgeEventNotification } from "../drawing/system-bridge";
import { SystemTimer } from "./timers/system-timer";
import { Unit } from "../utils/units";
export class BaseParticleSystem {
    constructor(manager) {
        this.manager = manager;
        this.links = { required: false };
        this._lastTickDelta = -1;
        this._lastTickTime = -1;
        this._systemTimer = new SystemTimer();
    }
    notifyWholeSystemChanged() {
        if (this.manager)
            this.manager.notify(SystemBridgeEventNotification.SYSTEM_UPDATED, this);
    }
    updateInternalParameters(delta, time) {
        this._lastTickDelta = delta;
        this._lastTickTime = time;
        this._systemTimer.check(time);
    }
    setTimeout(callback, timeout) {
        return this._systemTimer.add(callback, this._lastTickTime, this._lastTickTime + timeout, false);
    }
    setInterval(callback, timeout) {
        return this._systemTimer.add(callback, this._lastTickTime, this._lastTickTime + timeout, true);
    }
    clearTimeout(timerHandle) {
        return this._systemTimer.remove(timerHandle);
    }
    useLinks(distance, unit = Unit.PX) {
        this.links = {
            required: true,
            distance,
            unit
        };
    }
}
