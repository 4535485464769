export class PluginAdapter {
    constructor() {
        this._hooks = [];
    }
    addBefore(hook, callback, next) {
        this._hooks.push({ time: HookTime.BEFORE, type: hook, callback });
    }
    addAfter(hook, callback, next) {
        this._hooks.push({ time: HookTime.AFTER, type: hook, callback });
    }
    exec(hook, bridge) {
        this._hooks
            .forEach(h => h.type === hook && h.callback(bridge));
    }
    getAll(hook) {
        return this._hooks.filter(x => x.type === hook);
    }
}
export var HookTime;
(function (HookTime) {
    HookTime[HookTime["BEFORE"] = 0] = "BEFORE";
    HookTime[HookTime["AFTER"] = 1] = "AFTER";
})(HookTime || (HookTime = {}));
export var HookType;
(function (HookType) {
    HookType[HookType["RENDERER_INIT"] = 0] = "RENDERER_INIT";
    HookType[HookType["CONTEXT_INIT"] = 1] = "CONTEXT_INIT";
    HookType[HookType["CANVAS_INIT"] = 2] = "CANVAS_INIT";
    HookType[HookType["PRE_START"] = 3] = "PRE_START";
    HookType[HookType["DRAW"] = 4] = "DRAW";
    HookType[HookType["UPDATE"] = 5] = "UPDATE";
    HookType[HookType["CANVAS_CLEAR"] = 6] = "CANVAS_CLEAR";
    HookType[HookType["SYSTEM_UPDATED"] = 7] = "SYSTEM_UPDATED";
    HookType[HookType["WINDOW_RESIZE"] = 8] = "WINDOW_RESIZE";
})(HookType || (HookType = {}));
